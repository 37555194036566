<template>
  <div>
    <div class="e-breadcrumb">门店设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <el-form label-width="140px">
            <el-form-item size="small" label="门店名称:">
              <p v-text="department.departmentName"></p>
            </el-form-item>
            <el-form-item size="small" label="短信剩余量:">
              <p v-text="department.messageNum"></p>
            </el-form-item>
            <el-form-item size="small" label="门店电话:">
              <el-input size="small" class="form-container" v-model="department.mdTelephone"></el-input>
            </el-form-item>
            <el-form-item size="small" label="门店地址:">
              <el-input size="small" class="form-container" v-model="department.mdAddress"></el-input>
            </el-form-item>
            <el-form-item size="small" label="门店介绍:">
              <el-input size="small" type="textarea"  :rows="6" class="form-container" v-model="department.note" maxlength="198" ></el-input>
            </el-form-item>
            <el-form-item label="用户预约提醒:" required >
              <el-radio-group v-model="department.mbYyRemind">
                <el-radio :label="'0'">关闭</el-radio>
                <el-radio :label="'1'">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="用户消费提醒:" required >
              <el-radio-group v-model="department.mbXfRemind">
                <el-radio :label="'0'">关闭</el-radio>
                <el-radio :label="'1'">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="用户取消提醒:" required >
              <el-radio-group v-model="department.mbQxRemind">
                <el-radio :label="'0'">关闭</el-radio>
                <el-radio :label="'1'">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="员工取消提醒:" required >
              <el-radio-group v-model="department.empQxRemind">
                <el-radio :label="'0'">关闭</el-radio>
                <el-radio :label="'1'">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="员工占用提醒:" required >
              <el-radio-group v-model="department.empZyRemind">
                <el-radio :label="'0'">关闭</el-radio>
                <el-radio :label="'1'">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item size="small" label="门店 logo:">
              <el-upload
                class="avatar-uploader"
                :action="Base_url+'/admin/uploadImg'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item size="small" label="">
              <el-button type="primary" size="small" @click="saveDepartmentInfo">确定修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import {getToken} from "../../../utils/auth";
  import {Base_url} from '../../../config/index'

  export default {
    data() {
      return {
        time:'',
        list: [],
        map: Object,
        loginNameSearch: '',
        loginName: "",
        roleName: "",
        status: "",
        password: '',
        addVisible: false,
        editVisible: false,
        delVisible: false,
        imageUrl: '',
        Base_url: '',
        department: {},
      };
    },
    async created() {
      this.Base_url = Base_url
      this.getDepartmentInfo();
    },
    methods: {
      setDate(time){
       this.time=time
      },
      async getDepartmentInfo() {
        let data = {
          token: getToken()
        }
        let res = await this.$get("/admin/getDepartmentInfo", data)
        this.department = res
        this.imageUrl = res.logoUrl
        this.time=res.mdStartTime
      },

      handleAvatarSuccess(res) {
        this.imageUrl = res.fileUrl
      },

      beforeAvatarUpload(file) {

      },


      async saveDepartmentInfo() {
        let data = {
          id: this.department.id,
          mdTelephone: this.department.mdTelephone,
          mdAddress: this.department.mdAddress,
          note: this.department.note,
          logoUrl: this.imageUrl,
          mbYyRemind:this.department.mbYyRemind,
          mbXfRemind:this.department.mbXfRemind,
          mbQxRemind:this.department.mbQxRemind,
          empZyRemind:this.department.empZyRemind,
          empQxRemind:this.department.empQxRemind,
          // mdStartTime: this.department.mdStartTime,
          // mdEndTime: this.department.mdEndTime,
        }
        let res = await this.$post("/admin/updateDepartmentInfo", data)
        if (res.code == 200) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

      addEvent() {
        this.addVisible = true;
      },
      editEvent() {
        this.editVisible = true;
      },
      removeEvent() {
        this.delVisible = true;
      }
    }
  };
</script>

<style lang="scss">
  .form-container {
    width: 288px;
  }

  /*头像*/
  .avatar-uploader, .el-upload--picture-cardr {
    width: 120px !important;
    height: 120px !important;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 18px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }

  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
</style>
